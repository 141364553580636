import { ReactNode, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { useAdPageName } from '@/hooks/useAdPageName';
import { useAuthCookies } from '@/hooks/useAuthCookies';
import { useDidomiNoticeStore } from '@store/useDidomiNoticeStore';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Footer } from '@/components/molecules/Footer';
import { Navbar } from '@/components/molecules/Navbar';
import { AdPlaceholder } from '@/components/molecules/AdPlaceholder';
import { Separator } from '@/components/atoms/Separator';
import { getFirebaseRemoteConfig } from '@firebaseDir/remoteConfig';

import { useSetupContext } from '../AdCompanion/contexts/SetupContext';
import { SmartAds } from '../AdCompanion';

const DynamicInterstitialAd = dynamic(() => import('../AdCompanion/InterstitialAd').then((mod) => mod.InterstitialAd), {
  ssr: false,
});

export const metadata = {
  title: 'Fangol',
  description: 'Generated by create next app',
};

interface ILayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: ILayoutProps) => {
  const router = useRouter();
  const { token } = useAuthCookies();
  const { getAdBannerName } = useAdPageName();
  const { setFirebaseConfig } = useSetupContext();
  const isNoticeReady = useDidomiNoticeStore((state) => state.isNoticeReady);
  const { pathname } = router;
  const isLocker = pathname.includes('locker');
  const isUpload = pathname.includes('upload');
  const pagesWithAds = [`/`, `/locker`, '/post/[...slug]', '/categories/[categoryId]'].includes(pathname);
  const pageNameBackground = getAdBannerName('background');
  const pageNameInterstitialBanner = getAdBannerName('interstitial');

  useEffect(() => {
    if ((isUpload && !token) || (isLocker && !token)) window.location.href = '/';
  }, [isUpload, isLocker, token]);

  useEffect(() => {
    const fetchFirebaseConfig = async () => {
      const config = await getFirebaseRemoteConfig();
      setFirebaseConfig(config);
    };
    void fetchFirebaseConfig();
  }, []);

  return (
    <div className="min-h-screen relative">
      {/* <BackgroundAd
        pageName={pageNameBackground}
        isConsentNoticeReady={isNoticeReady}
        getRemoteConfigValue={getRemoteConfigValue}
      /> */}
      <Wrapper className="items-center min-h-screen" fullWidth>
        <Wrapper
          className="max-w-laptop min-h-screen justify-center relative pt-[102px] md:pt-[136px] md:px-6"
          fullWidth
        >
          <Navbar />
          {pagesWithAds && (
            <SmartAds
              variant="topNavbar"
              adPlaceholder={<AdPlaceholder variant="topNavbar" />}
              adSeparator={<Separator />}
            />
          )}
          <main className="text-white py-0.5 mb-16 flex flex-1">
            <Toaster containerClassName="[&>div]:!hidden [&>div:first-child]:!flex [&>div:nth-child(3)]:!flex [&>div:nth-child(2)]:!flex mt-20" />
            {children}
            {/* <DynamicInterstitialAd
              isConsentNoticeReady={isNoticeReady}
              pageName={pageNameInterstitialBanner}
              getRemoteConfigValue={getRemoteConfigValue}
            /> */}
          </main>
          {!isUpload && <Footer />}
        </Wrapper>
      </Wrapper>
      {pagesWithAds && <SmartAds variant="stickedBanner" />}
    </div>
  );
};
