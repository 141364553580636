import { ReactNode } from 'react';
import { RemoteConfig } from 'firebase/remote-config';

import { SmartAds } from '../SmartAds';
import { useAdZoneValue } from '../hooks';

interface IFeedAdsProps {
  index: number;
  pageName: string;
  adSeparator?: ReactNode;
  adPlaceholder?: ReactNode;
  getRemoteConfigValue: (config: RemoteConfig, key: string) => Promise<string | null>;
}

export const FeedAds = ({ index, pageName, adSeparator, adPlaceholder, getRemoteConfigValue }: IFeedAdsProps) => {
  const { feedAdConfig } = useAdZoneValue({ pageName, getRemoteConfigValue });

  const generateIndexesWithAd = (
    count: number | undefined,
    offset: number | undefined,
    startIndex: number | undefined,
  ) => {
    if (!count || !offset || !startIndex) return;

    const postIndex = startIndex - 1;

    const result = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < count; i++) {
      result.push(postIndex + i * offset);
    }

    // eslint-disable-next-line consistent-return
    return result;
  };

  const indexesWithAds = generateIndexesWithAd(feedAdConfig?.count, feedAdConfig?.offset, feedAdConfig?.start);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {indexesWithAds?.includes(index) && (
        <SmartAds variant="inFeed" adSeparator={adSeparator} adPlaceholder={adPlaceholder} id={index} />
      )}
      {!indexesWithAds?.includes(index) && index !== 0 && <div />}
    </>
  );
};
