import { ReactNode, forwardRef } from 'react';

import { cn } from '@/utils/cn';
import { BannerVariantType } from './types';

import { AdsContainer } from './AdsContainer';

interface SmartAdsProps {
  className?: string;
  adSeparator?: ReactNode;
  adPlaceholder?: ReactNode;
  variant: BannerVariantType;
  id?: number;
}

export const SmartAds = forwardRef<HTMLDivElement, SmartAdsProps>(
  ({ variant, adSeparator, className, adPlaceholder, id }, ref) => {
    const isPlaceholderVisible = !!adPlaceholder;
    const isStickyBanner = variant === 'stickedBanner';

    return (
      <>
        <AdsContainer
          ref={ref}
          variant={variant}
          isPlaceholderVisible={isPlaceholderVisible}
          className={cn(className, isStickyBanner && 'border-t border-gray-800')}
          id={id}
        >
          {adPlaceholder}
        </AdsContainer>
        {!!adSeparator && adSeparator}
      </>
    );
  },
);
